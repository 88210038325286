import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import OptionCard from "../../../components/cards/optionCard";
import Seo from "../../../components/seo";
import {message} from "antd";

export default () => {
  return (<>
    <PersonalLayout selected='enterprise'>
      <PersonalBody
        title='企业信息'
      >
        <OptionCard
          title='发票信息'
          desc='请完善企业发票信息'
          onClick={() => {
            window.open("https://vika.cn/share/shr1iq1srhGZTgBlxDPQX")
          }}
        />
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export const Head = () => <Seo />
